/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */

import React, { FC, useRef, useCallback, useEffect, useState, useContext } from "react";
import ReactGA from "react-ga4";
import Countdown from "react-countdown";
import _ from "lodash";
import { Link } from "react-router-dom";
import { useFormikContext } from "formik";

import { Box, Grid, FormControl, CircularProgress } from "@mui/material";
import { makeStyles } from "@material-ui/core";

import {
  Input,
  Typography,
  Button,
  OzonSelect,
  Select,
} from "design_system/src";
import { ReactComponent as Moto } from "design_system/src/static/icons/motorcycle.svg";
import { ReactComponent as Email } from "design_system/src/static/icons/email.svg";
import { ReactComponent as SuccessPlan } from "design_system/src/static/icons/success-plan.svg";
import { ReactComponent as FailedPlan } from "design_system/src/static/icons/failed-plan.svg";
import { ReactComponent as Chat } from "design_system/src/static/icons/group-chat.svg";
import { ReactComponent as Baby } from "design_system/src/static/icons/baby.svg";
import { ReactComponent as Curp } from "design_system/src/static/icons/user-card-2.svg";
import { ReactComponent as Groups } from "design_system/src/static/icons/groups.svg";
import { ReactComponent as Building } from "design_system/src/static/icons/building.svg";
import { ReactComponent as CoupleLove } from "design_system/src/static/icons/couple-love.svg";
import { ReactComponent as Boy } from "design_system/src/static/icons/boy.svg";
import { ReactComponent as Phone } from "design_system/src/static/icons/phone.svg";
import { ReactComponent as Map } from "design_system/src/static/icons/map.svg";
import { ReactComponent as Mail } from "design_system/src/static/icons/mail.svg";
import { ReactComponent as NumbE } from "design_system/src/static/icons/numbered-list.svg";
import { ReactComponent as TickCircle } from "design_system/src//static/icons/tick-circle.svg";

import { LeadFormFieldsEnum } from "models/lead/enums/lead.enums";
import { EconomicActivityEnum, AssetsEnum } from "models/shared/enums/shared.enums";
import {
  CivilStatusEnum,
  EducationLevelEnum,
  LivesWithEnum
} from "models/shared/enums/personalInformation.enums";
import { FormApplicationContext, IStepperFormValues } from "views/formApplication/context/context";

import IncomeCard from "components/creditCalculationStepper/incomeCard/IncomeCard";
import FamilyCard from "components/creditCalculationStepper/familyCard/FamilyCard";
import CustomInputTitle from "components/customInputTitle/CustomInputTitle";
import AssetCard from "components/assetCard/assetCard";
import CustomCheckBox from "components/customCheckBox/CustomCheckBox";

import NoneIcon from "static/icons/codeBar.png";
import HomeIcon from "static/icons/houseIcon.png";
import CarIcon from "static/icons/carIcon.svg";
import MotoIcon from "static/icons/motoIcon.png";
import CubeIcon from "static/icons/cubeIcon .png";

import { createForm, sendSms, submitForm, verifyNip } from "models/moffin/controllers/moffin.controller";
import { AcceptanceMethodEnum } from "models/shared/enums/acceptanceMethod.enums";
import { getLead } from "models/lead/controllers/lead.controller";
import { countryCodes, getCountry, formatPhone, unformatPhone } from "helpers/phone";
import { fetchByPostalCode } from "../../helpers/moffinCP";
import { ModalTermsAndConditions } from "../ModalTermsAndConditions/ModalTermsAndConditions";

type StepProps = {
  goBack?: any;
};

export const EmailStep: React.FC<StepProps> = () => {
  const [open, setOpen] = useState<boolean>(false);
  const {
    values,
    handleChange,
    errors,
    handleBlur,
    getFieldMeta,
    setFieldValue,
  } = useFormikContext<IStepperFormValues>();
  const [authDataTreatment, setAuthDataTreatment] = useState<boolean>(
    () => _.get(values, LeadFormFieldsEnum.TERMS_OF_SERVICE_ACCEPTANCE) !== undefined ||
      _.get(values, LeadFormFieldsEnum.PRIVACY_POLICY_ACCEPTANCE) !== undefined
  );

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleCheckboxChange = useCallback(() => {
    setAuthDataTreatment(true);
    setOpen(false);
  }, [setFieldValue]);

  const onCheckboxChange = useCallback(
    (e) => {
      // eslint-disable-next-line default-case
      switch (e.target.value) {
        case "1":
          setAuthDataTreatment(e.target.checked);
          break;
      }
    },
    [setFieldValue]
  );

  useEffect(() => {
    if (authDataTreatment) {
      setFieldValue(
        LeadFormFieldsEnum.TERMS_OF_SERVICE_ACCEPTANCE,
        {
          acceptedAt: new Date().toISOString(),
          termsVersion: "v1.0",
          acceptanceMethod: AcceptanceMethodEnum.checkBox,
        }
      );
      setFieldValue(
        LeadFormFieldsEnum.PRIVACY_POLICY_ACCEPTANCE,
        {
          acceptedAt: new Date().toISOString(),
          privacyPolicyVersion: "v1.0",
          acceptanceMethod: AcceptanceMethodEnum.checkBox,
        }
      );
    } else {
      setFieldValue(LeadFormFieldsEnum.TERMS_OF_SERVICE_ACCEPTANCE, undefined);
      setFieldValue(LeadFormFieldsEnum.PRIVACY_POLICY_ACCEPTANCE, undefined);
    }
  }, [authDataTreatment]);

  useEffect(() => {
    ReactGA.event("VIEW_Financial_email", {
      category: "VIEW_Financial_email",
      label: "page view to the email",
    });
  }, []);

  return (
    <div className="flex_1 p_y_xxl p_x_md_mobile">
      <div className="w_60_per_desktop m_x_auto">
        <Typography
          weight="400"
          scale="medium"
          className="text_center m_b_lg"
        >
          <span style={{ fontWeight: "bold" }}>
            ¡Escribe tu correo electrónico
          </span>{" "}
          y da los primeros pasos para adquirir la moto de tus
          sueños!
        </Typography>
        <Input
          name={LeadFormFieldsEnum.EMAIL}
          placeholder="Escribe tu correo aquí"
          onBlur={handleBlur}
          onChange={handleChange}
          value={_.get(values, LeadFormFieldsEnum.EMAIL)}
          title="Correo"
          icon={<Email />}
          type="text"
          error={
            !!_.get(errors, LeadFormFieldsEnum.EMAIL) &&
            getFieldMeta(LeadFormFieldsEnum.EMAIL).touched
          }
          subtitle={
            getFieldMeta(LeadFormFieldsEnum.EMAIL).touched
              ? (_.get(errors, LeadFormFieldsEnum.EMAIL))
              : undefined
          }
        />
        <Input
          name="extra"
          checked={authDataTreatment}
          onChange={onCheckboxChange}
          className="border_neutral_700 center_x w_fit"
          title=""
          options={[
            {
              label: (
              <>
                Autorizo el uso de mis datos de acuerdo al{" "}
                <Link to="/aviso-de-privacidad" target="_blank">
                  Aviso de Privacidad
                </Link>{" "}
                y acepto los
                <span
                  onClick={handleOpen}
                  style={{
                    cursor: "pointer",
                    color: "blue",
                    textDecoration: "underline",
                  }}
                >
                {" "}
                  Términos y Condiciones.
                </span>
              </>
              ),
              value: 1,
            },
          ]}
          type="checkbox"
        />
      </div>
      <ModalTermsAndConditions
        open={open}
        setOpen={handleOpen}
        handleCheckboxChange={handleCheckboxChange}
      />
  </div>
  );
};

export const PersonalInformationStep: React.FC<StepProps> = () => {
  const {
    values,
    handleChange,
    errors,
    handleBlur,
    getFieldMeta,
    setFieldValue
  } = useFormikContext<IStepperFormValues>();

  useEffect(() => {
    ReactGA.event("VIEW_Financial_info", {
      category: "VIEW_Financial_info",
      label: "page view to the basic personal information view",
    });
  }, []);

  return (
    <div className="animate__animated animate__fadeIn">
      <Box sx={{ width: "100%" }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item sm={6} xs={12}>
            <Input
              type="text"
              title="Nombres"
              name={LeadFormFieldsEnum.NAME}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Escribe tu nombre aquí"
              icon={<Boy className="primary_300" />}
              value={_.get(values, LeadFormFieldsEnum.NAME)}
              error={
                !!_.get(errors, LeadFormFieldsEnum.NAME) &&
                getFieldMeta(LeadFormFieldsEnum.NAME).touched
              }
              subtitle={
                getFieldMeta(LeadFormFieldsEnum.NAME).touched
                  ? _.get(errors, LeadFormFieldsEnum.NAME)
                  : undefined
              }
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <Input
              type="text"
              title="Apellidos"
              name={LeadFormFieldsEnum.LAST_NAME}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Escribe tus apellidos aquí"
              icon={<Boy className="primary_300" />}
              value={_.get(values, LeadFormFieldsEnum.LAST_NAME)}
              error={
                !!_.get(errors, LeadFormFieldsEnum.LAST_NAME) &&
                getFieldMeta(LeadFormFieldsEnum.LAST_NAME).touched
              }
              subtitle={
                getFieldMeta(LeadFormFieldsEnum.LAST_NAME).touched
                  ? _.get(errors, LeadFormFieldsEnum.LAST_NAME)
                  : undefined
              }
            />
          </Grid>
        </Grid>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item sm={6} xs={12}>
            <Input
              type="text"
              title="CURP"
              name={LeadFormFieldsEnum.CURP}
              icon={<Curp className="custom-input-color" />}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                e.target.value = e.target.value.toUpperCase();
                handleChange(e);
              }}
              placeholder="Escribe tu curp"
              value={_.get(values, LeadFormFieldsEnum.CURP)}
              error={
                !!_.get(errors, LeadFormFieldsEnum.CURP) &&
                getFieldMeta(LeadFormFieldsEnum.CURP).touched
              }
              subtitle={
                getFieldMeta(LeadFormFieldsEnum.CURP).touched
                  ? _.get(errors, LeadFormFieldsEnum.CURP)
                  : undefined
              }
            />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export const AddressStep: React.FC<StepProps> = () => {
  const [neighborhoodOptions, setNeighborhoodOptions] = useState<string[]>([]);

  const {
    values,
    handleChange,
    errors,
    handleBlur,
    getFieldMeta,
    setFieldValue,
    setFieldError,
  } = useFormikContext<IStepperFormValues>();

  const handleChangeCp = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(e);
    if (e.target.value.length === 5) {
      setFieldValue(LeadFormFieldsEnum.STATE, "");
      setFieldValue(LeadFormFieldsEnum.STATE_CODE, "");
      setFieldValue(LeadFormFieldsEnum.CITY, "");
      setFieldValue(LeadFormFieldsEnum.DELEGATION, "");
      setFieldValue(LeadFormFieldsEnum.NEIGHBORHOOD, "");
      fetchByPostalCode(e.target.value)
        .then((fullAddress) => {
          setFieldValue(LeadFormFieldsEnum.STATE, fullAddress.state);
          setFieldValue(LeadFormFieldsEnum.STATE_CODE, fullAddress.stateCode);
          setFieldValue(LeadFormFieldsEnum.CITY, fullAddress.city);
          setFieldValue(
            LeadFormFieldsEnum.DELEGATION,
            fullAddress.delegation
          );
          setNeighborhoodOptions(fullAddress.neighborhoods);
        })
        .catch((err) => {
          setFieldError(LeadFormFieldsEnum.ZIP_CODE, "El código postal no existe");
          setNeighborhoodOptions([]);
          setFieldError("zipcode", "¡No se encontró el código postal!");
        });
    }
  };

  useEffect(() => {
    ReactGA.event("VIEW_Financial_zipcode", {
      category: "VIEW_Financial_zipcode",
      label: "page view to the zip code address fill",
    });
  }, []);

  return (
    <div className="animate__animated animate__fadeIn">
      <Grid container rowSpacing={0} columnSpacing={2}>
        <Grid item xs={12}>
          <Input
            type="text"
            title="Código postal*"
            name={LeadFormFieldsEnum.ZIP_CODE}
            onChange={(e) => handleChangeCp(e)}
            onBlur={handleBlur}
            placeholder="Escribe el código postal"
            icon={<Mail className="primary_300" />}
            value={_.get(values, LeadFormFieldsEnum.ZIP_CODE)}
            error={
              !!_.get(errors, LeadFormFieldsEnum.ZIP_CODE) &&
              getFieldMeta(LeadFormFieldsEnum.ZIP_CODE).touched
            }
            subtitle={
              getFieldMeta(LeadFormFieldsEnum.ZIP_CODE).touched
                ? _.get(errors, LeadFormFieldsEnum.ZIP_CODE,"")
                : undefined
            }
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Input
            type="select"
            title="Colonia*"
            name={LeadFormFieldsEnum.NEIGHBORHOOD}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Seleciona la colonia"
            options={neighborhoodOptions}
            icon={<Map className="primary_300" />}
            value={_.get(values, LeadFormFieldsEnum.NEIGHBORHOOD)}
            error={
              !!_.get(errors, LeadFormFieldsEnum.NEIGHBORHOOD) &&
              getFieldMeta(LeadFormFieldsEnum.NEIGHBORHOOD).touched
            }
            subtitle={
              getFieldMeta(LeadFormFieldsEnum.NEIGHBORHOOD).touched
                ? _.get(errors, LeadFormFieldsEnum.NEIGHBORHOOD,"")
                : undefined
            }
          />
        </Grid>

        <Grid item sm={6} xs={12}>
          <Input
            type="text"
            title="Calle*"
            name={LeadFormFieldsEnum.STREET}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Escribe la calle"
            icon={<Map className="primary_300" />}
            value={_.get(values, LeadFormFieldsEnum.STREET)}
            error={
              !!_.get(errors, LeadFormFieldsEnum.STREET) &&
              getFieldMeta(LeadFormFieldsEnum.STREET).touched
            }
            subtitle={
              getFieldMeta(LeadFormFieldsEnum.STREET).touched
                ? (_.get(errors, LeadFormFieldsEnum.STREET,"") as string)
                : undefined
            }
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Input
            type="text"
            title="Número Exterior*"
            name={LeadFormFieldsEnum.EXT_NUMBER}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Escribe el número exterior"
            icon={<NumbE className="primary_300" />}
            value={_.get(values, LeadFormFieldsEnum.EXT_NUMBER)}
            error={
              !!_.get(errors, LeadFormFieldsEnum.EXT_NUMBER) &&
              getFieldMeta(LeadFormFieldsEnum.EXT_NUMBER).touched
            }
            subtitle={
              getFieldMeta(LeadFormFieldsEnum.EXT_NUMBER).touched
                ? (_.get(errors, LeadFormFieldsEnum.EXT_NUMBER,"") as string)
                : undefined
            }
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Input
            type="text"
            title="Número Interior"
            name={LeadFormFieldsEnum.INT_NUMBER}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Escribe el número interior"
            icon={<NumbE className="primary_300" />}
            value={_.get(values, LeadFormFieldsEnum.INT_NUMBER)}
            error={
              !!_.get(errors, LeadFormFieldsEnum.INT_NUMBER) &&
              getFieldMeta(LeadFormFieldsEnum.INT_NUMBER).touched
            }
            subtitle={
              getFieldMeta(LeadFormFieldsEnum.INT_NUMBER).touched
                ? _.get(errors, LeadFormFieldsEnum.INT_NUMBER)
                : undefined
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Typography
            scale="small"
            weight="600"
            textColor="neutral_700"
            className="m_b_md"
          >
            Resumen
          </Typography>
          <Typography
            scale="small"
            weight="600"
            textColor="neutral_700"
            className="w_100_per p_y_xs p_x_md bg_neutral_300 br_xxs border_1 border_neutral_500 shadow_soft border_solid m_b_md"
          >
            {`${_.get(values, LeadFormFieldsEnum.STREET) || ""} ${
              _.get(values, LeadFormFieldsEnum.EXT_NUMBER) || ""
            } ${_.get(values, LeadFormFieldsEnum.INT_NUMBER) || ""}, ${
              _.get(values, LeadFormFieldsEnum.NEIGHBORHOOD) || ""
            }, ${_.get(values, LeadFormFieldsEnum.DELEGATION) || ""}, ${
              _.get(values, LeadFormFieldsEnum.STATE) || ""
            }, MEX, C.P ${_.get(values, LeadFormFieldsEnum.ZIP_CODE) || ""}`}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export const PhoneStep: React.FC<StepProps> = () => {
  const {
    values,
    handleChange,
    errors,
    handleBlur,
    setFieldValue,
    getFieldMeta,
  } = useFormikContext<IStepperFormValues>();
  const { lead } = useContext(FormApplicationContext);
  const [isMoffinCreated, setMoffinCreated] = useState(false);
  const [country, setCountry] = useState<string>(
    _.get(values, LeadFormFieldsEnum.PHONE, "").startsWith("57")
      ? "co"
      :"mx"
  );

  useEffect(() => {
    ReactGA.event("VIEW_Financial_phone", {
      category: "VIEW_Financial_phone",
      label: "page view to the phone information",
    });

    if (lead.moffin?._id) {
      setMoffinCreated(true);
    }

    if (_.get(values, LeadFormFieldsEnum.PHONE, "").startsWith("57")) {
      setCountry("co");
    }
  }, []);

  const getFlagLink = (countryCode: string): string => `https://flagcdn.com/w80/${countryCode}.png`;
  const getLabel = (countryCode: string): string => getCountry(countryCode).label;

  const updatePhone = (value: string) => {
    const phoneValue = formatPhone(value, country);
    setFieldValue(LeadFormFieldsEnum.PHONE, phoneValue);
  };

  const handlePhoneChange = (e: any) => {
    handleChange(e);
    updatePhone(e.target.value);
  };

  useEffect(() => {
    if (_.get(values, LeadFormFieldsEnum.PHONE)) updatePhone(
      unformatPhone(_.get(values, LeadFormFieldsEnum.PHONE)!)
    );
  }, [country]);

  return (
    <div className="animate__animated animate__fadeIn">
      <Box sx={{ width: "100%" }}>
        <Typography scale="medium" weight="400" className="m_y_lg text_neutral_700">
          Ingresa tus datos de contacto
        </Typography>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item sm={12} xs={12}>
            <div className="dso_input_cont">
              <div className="dso_input_title m_b_none">
                <div className="dso_input_img">
                  <Phone />
                </div>
                <div className="dso_input_text">Celular</div>
              </div>
            </div>
            <div className="w_100_per display_flex flex_align_start flex_gap_xs">
              {(process.env.REACT_APP_ENV !== "production" && !isMoffinCreated) && <div className="display_flex flex_center flex_gap_xxs w_50_per_mobile w_30_per_desktop m_y_md_mobile m_y_sm_desktop p_y_xxs_desktop br_xxs">
                <img
                  src={getFlagLink(country)}
                  alt="mx"
                  height="15"
                />
                <Select
                  variant="default"
                  name='countryCode'
                  value={country}
                  onChange={(value: any) => {setCountry(value.target.value);}}
                  options={countryCodes.map(({ code, label }) => ({ value: code, label }))}
                />
              </div>}
              {(process.env.REACT_APP_ENV === "production" || isMoffinCreated) && <div className="display_flex flex_center flex_gap_xxs w_30_per bg_neutral_400 m_y_md_mobile m_y_sm_desktop p_y_sm_desktop  p_y_xs_mobile br_xxs">
                <img
                  src={getFlagLink(country)}
                  alt="mx"
                  height="15"
                />
                <Typography scale="small" weight="600" className="text_neutral_800">
                  {getLabel(country)}
                </Typography>
              </div>}

              <div className="w_70_per">
                <Input
                  title=""
                  type="text"
                  disabled={isMoffinCreated}
                  name={LeadFormFieldsEnum.PHONE}
                  onChange={handlePhoneChange}
                  onBlur={handleBlur}
                  placeholder="1234567890"
                  value={_.get(values, LeadFormFieldsEnum.PHONE)
                    ? unformatPhone(_.get(values, LeadFormFieldsEnum.PHONE)!)
                    : undefined
                  }
                  error={
                    !!_.get(errors, LeadFormFieldsEnum.PHONE) &&
                    getFieldMeta(LeadFormFieldsEnum.PHONE).touched
                  }
                  subtitle={
                    getFieldMeta(LeadFormFieldsEnum.PHONE).touched
                      ? _.get(errors, LeadFormFieldsEnum.PHONE)
                      : undefined
                  }
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

type countDown = {
  hours: number;
  minutes: number;
  seconds: number;
  completed: boolean;
}

export const PhoneValidationStep: React.FC<StepProps> = () => {
  const { values, setFieldValue } = useFormikContext<IStepperFormValues>();
  const { lead, setLead } = useContext(FormApplicationContext);
  const [dialCode, setDialCode] = useState<number>(52);
  const [formattedPhone, setFormattedPhone] = useState<string>("");

  const [isPhoneVerified, setPhoneVerified] = useState(false);
  const [isCodeSended, setIsCodeSended] = useState(false);
  const [targetDate, setTargetDate] = useState(Date.now());
  const [loading, setLoading] = useState(false);

  const [code, setCode] = useState<string[]>(["", "", "", ""]);
  const inputsRef = useRef<HTMLInputElement[]>([]);

  const cleanCode = () => setCode(["", "", "", ""]);

  const sendMoffinCode = async (id: string) => {
    const { remainingAttempts, sent } = await sendSms(id);
    setLoading(false);
    setTargetDate(Date.now() + 180000);

    if (sent) {
      setIsCodeSended(remainingAttempts > 0);
    }
  };

  const getMoffin = async () => {
    const leadData = await getLead(lead._id!);
    setLead(leadData);
    if (leadData.moffin?._id) {
      await sendMoffinCode(leadData.moffin?._id!);
    }
    setLoading(false);
  };

  const validateCode = async () => {
    setLoading(true);

    const { validated } = await verifyNip(lead.moffin?._id!, code.join(""));
    setPhoneVerified(validated);

    if (validated) {
      await submitForm(lead.moffin?._id!, code.join(""));
      setFieldValue(LeadFormFieldsEnum.PHONE_VALIDATION_DATE, new Date());
      setIsCodeSended(false);
    } else {
      cleanCode();
    }
    setLoading(false);
  };

  useEffect(() => {
    const phone: string = _.get(values, LeadFormFieldsEnum.PHONE)!;
    setDialCode(phone.startsWith("52") ? 52 : 57);
    setFormattedPhone(unformatPhone(phone));
  }, [values]);

  const renderer = ({ hours, minutes, seconds, completed }: countDown) => {
    if (completed) {
      return <Typography scale="small" weight="400" className="text_neutral_700">
        ¿No recibiste el código? <Button variant="ghost" onClick={() => sendMoffinCode(lead.moffin?._id!)}>
          <Typography scale="small" weight="400" className="text_secondary_500">
            Reenviar ahora
          </Typography>
        </Button>
      </Typography>;
    }

    return <Typography scale="small" weight="400" className="text_neutral_700 m_t_md">
      ¿No recibiste el código? <span className="text_secondary_500">Reenviar en 0{minutes}:{seconds > 9 ? seconds : `0${seconds}`}</span>
    </Typography>;
  };

  useEffect(() => {
    ReactGA.event("VIEW_Financial_phone_validation", {
      category: "VIEW_Financial_phone_validation",
      label: "page view to the phone validation",
    });

    if (_.get(values, LeadFormFieldsEnum.PHONE_VALIDATION_DATE)) {
      setPhoneVerified(true);
      setLoading(false);
    } else {
      getMoffin();
    }
  }, []);

  useEffect(() => {
    if (code.join("").length === 4) validateCode();
  }, [code]);

  const handleCodeChange = (value: string, index: number) => {
    if (/^\d$/.test(value) || value === "") {
      const newCode = [...code];
      newCode[index] = value;
      setCode(newCode);

      if (value && index < 3) {
        inputsRef.current[index + 1]?.focus();
      }
    }
  };

  const handleBackspace = (e: React.KeyboardEvent, index: number) => {
    if (e.key === "Backspace" && code[index] === "" && index > 0) {
      inputsRef.current[index - 1]?.focus();
    }
  };

  return (
    <div className="animate__animated animate__fadeIn">
      <Box sx={{ width: "100%" }}>
        <Typography scale="medium" weight="600" className="m_y_lg text_neutral_700">
          Confirma tu número
        </Typography>

        {isPhoneVerified && <div className="display_flex flex_align_center">
          <div>
            <TickCircle className="m_r_sm w_20_px text_green_300"/>
          </div>
          <Typography scale="small" weight="400" className="m_y_lg text_neutral_700">
            Tu número <strong>+{dialCode} {formattedPhone}</strong> ha sido verificado correctamente. Por favor, continua al siguiente paso. <br/>
          </Typography>
        </div>}

        {!isPhoneVerified && <>
          <Typography scale="small" weight="400" className="m_y_lg text_neutral_700">
            Ingresa el código que te enviamos por SMS al <strong>+{dialCode} {formattedPhone}</strong>
          </Typography>

          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item sm={6} xs={12}>
              <div className="display_flex">
                <div className="display_flex flex_justify_start flex_align_center flex_gap_md w_300_px m_y_md">
                  {code.map((digit, index) => (
                    <input
                      key={index}
                      type="text"
                      value={digit}
                      maxLength={1}
                      disabled={loading || isPhoneVerified}
                      onChange={(e) => handleCodeChange(e.target.value, index)}
                      onKeyDown={(e) => handleBackspace(e, index)}
                      ref={(el) => {inputsRef.current[index] = el!;}}
                      className="input-code"
                    />
                  ))}
                  {loading && <CircularProgress size={22} sx={{ color: "#FE8A02" }} />}
                </div>
              </div>
            </Grid>
          </Grid>
          {isCodeSended && <Countdown date={targetDate} renderer={renderer}/>}
        </>}
      </Box>
    </div>
  );
};

export const FinancialInformationStep: React.FC<StepProps> = () => {
  const {
    values,
  } = useFormikContext<IStepperFormValues>();

  useEffect(() => {
    ReactGA.event("VIEW_Financial_income", {
      category: "VIEW_Financial_income",
      label: "page view to the income and outcome information view",
    });
  }, []);
  return (
    <div className="animate__animated animate__fadeIn">
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item sm={6} xs={12}>
          <IncomeCard
            icon={<SuccessPlan className="text_primary_300" />}
            placeholder="Escribe tu ingreso promedio mensual"
            label={
              <Typography scale="medium" weight="400" textColor="neutral_600" className="text_center">
                ¿Cuáles son tus{" "}
                <Typography
                  component="span"
                  scale="medium"
                  weight="600"
                  textColor="neutral_800"
                >
                  ingresos promedio mensual?
                </Typography>{" "}
              </Typography>
            }
            initValue={_.get(values, LeadFormFieldsEnum.MONTHLY_INCOME)}
            name={LeadFormFieldsEnum.MONTHLY_INCOME}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <IncomeCard
            icon={<FailedPlan className="text_primary_300" />}
            placeholder="Escribe tu egreso promedio mensual"
            label={
              <Typography scale="medium" weight="400" textColor="neutral_600" className="text_center">
                ¿Cuáles son tus{" "}
                <Typography
                  component="span"
                  scale="medium"
                  weight="600"
                  textColor="neutral_800"
                >
                  gastos promedio mensual?
                </Typography>{" "}
              </Typography>
            }
            initValue={_.get(values, LeadFormFieldsEnum.MONTHLY_OUTCOME)}
            name={LeadFormFieldsEnum.MONTHLY_OUTCOME}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export const DependantsStep: React.FC<StepProps> = () => {
  useEffect(() => {
    ReactGA.event("VIEW_Financial_dependants", {
      category: "VIEW_Financial_dependants",
      label: "page view to the dependants information view",
    });
  }, []);

  return (
    <div className="animate__animated animate__fadeIn">
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12} sm={12} md={6}>
          <FamilyCard
            name={LeadFormFieldsEnum.CHILDREN}
            icon={<Baby className="text_primary_300" />}
            label="¿Cuántos hijos tienes?"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <FamilyCard
            name={LeadFormFieldsEnum.DEPENDANTS}
            icon={<Chat className="text_primary_300" />}
            label="¿Cuántas personas dependen de ti económicamente?"
          />
        </Grid>
      </Grid>
    </div>
  );
};

export const EmploymentAndEducationStep: React.FC<StepProps> = () => {
  const { values, handleChange, errors, handleBlur, getFieldMeta, setFieldValue } =
    useFormikContext<IStepperFormValues>();

    const activityOptions = [
      { label: "Empleado", value: EconomicActivityEnum.empleado },
      { label: "Repartidor", value: EconomicActivityEnum.repartidor },
      { label: "Comerciante", value: EconomicActivityEnum.comerciante },
      { label: "Conductor", value: EconomicActivityEnum.conductor },
      { label: "Conductor repartidor", value: EconomicActivityEnum.conductorRepartidor },
      { label: "Ayudante general", value: EconomicActivityEnum.ayudanteGeneral },
      { label: "Seguridad", value: EconomicActivityEnum.seguridad },
      { label: "Independiente", value: EconomicActivityEnum.independiente },
      { label: "Estudiante", value: EconomicActivityEnum.estudiante },
      { label: "Policía", value: EconomicActivityEnum.policia },
      { label: "Servidor público", value: EconomicActivityEnum.servidorPublico },
      { label: "Enfermero", value: EconomicActivityEnum.enfermero },
      { label: "Mecánico", value: EconomicActivityEnum.mecanico },
      { label: "Otro", value: EconomicActivityEnum.otro },
    ];

    const educationOptions = [
      { label: "Primaria", value: EducationLevelEnum.primary },
      { label: "Secundaria", value: EducationLevelEnum.secondary },
      { label: "Preparatoria", value: EducationLevelEnum.HighSchool },
      { label: "Tecnicatura", value: EducationLevelEnum.AssociateDegree },
      { label: "Licenciatura", value: EducationLevelEnum.BachelorDegree },
      { label: "Maestría", value: EducationLevelEnum.MasterDegree },
    ];

  const trueValue = "sí";
  const falseValue = "no";

  const digitalPlatformWorkers = [
    { value: trueValue, label: "Sí"},
    { value: falseValue, label: "No"}
  ];

  useEffect(() => {
    ReactGA.event("VIEW_Financial_occupation", {
      category: "VIEW_Financial_occupation",
      label: "page view to the occupation information view",
    });
  }, []);

  const handleChangeDelivery = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue(
      LeadFormFieldsEnum.WORK_IN_DIGITAL_PLATFORMS,
      e.target.value === trueValue
    );
  };

  const handleChangeOcupation = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(e);
    setFieldValue(
      LeadFormFieldsEnum.WORK_IN_DIGITAL_PLATFORMS,
      e.target.value === EconomicActivityEnum.conductorRepartidor
    );
  };

  return (
    <div className="animate__animated animate__fadeIn">
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item sm={12} xs={12}>
          <div>
            <Input
              title="¿Eres repartidor en plataformas como Uber, Rappi, Didi u otras?"
              name={LeadFormFieldsEnum.WORK_IN_DIGITAL_PLATFORMS}
              type="radio"
              inputClassName=""
              icon={<Moto />}
              className="center_x m_t_xl"
              titleClassName=""
              value={_.get(values, LeadFormFieldsEnum.WORK_IN_DIGITAL_PLATFORMS, false) ? trueValue : falseValue}
              options={digitalPlatformWorkers}
              onChange={handleChangeDelivery}
            />
          </div>
        </Grid>
        <Grid item sm={6} xs={12}>
          <div className="dso_input_cont">
            <div className="dso_input_title">
              <div className="dso_input_img">
                <Groups className="custom-input-color" />
              </div>
              <div className="dso_input_text">¿Cuál es tu ocupación?</div>
            </div>
            <FormControl sx={{ minWidth: "100%" }} size="small">
              <OzonSelect
                itemsLabel="¿Cuál es tu ocupación?"
                fieldName={LeadFormFieldsEnum.ECONOMIC_ACTIVITY_VALUE}
                options={activityOptions}
                handleBlur={handleBlur}
                handleChange={(e: any) => handleChangeOcupation(e)}
                values={values}
                getFieldMeta={getFieldMeta}
                errors={errors}
              />
            </FormControl>
          </div>
        </Grid>
        <Grid item sm={6} xs={12}>
          {_.get(values, LeadFormFieldsEnum.ECONOMIC_ACTIVITY_VALUE) === EconomicActivityEnum.otro && <Input
            type="text"
            title="Describe tu ocupación"
            name={LeadFormFieldsEnum.ECONOMIC_ACTIVITY_DESCRIPTION}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Escribe tu ocupación aquí"
            icon={<Groups className="primary_300" />}
            value={_.get(values, LeadFormFieldsEnum.ECONOMIC_ACTIVITY_DESCRIPTION)}
            error={
              !!_.get(errors, LeadFormFieldsEnum.ECONOMIC_ACTIVITY_DESCRIPTION) &&
              getFieldMeta(LeadFormFieldsEnum.ECONOMIC_ACTIVITY_DESCRIPTION).touched
            }
            subtitle={
              getFieldMeta(LeadFormFieldsEnum.ECONOMIC_ACTIVITY_DESCRIPTION).touched
                ? (_.get(
                    errors,
                    LeadFormFieldsEnum.ECONOMIC_ACTIVITY_DESCRIPTION
                    ,"") as string)
                : undefined
            }
          />}
        </Grid>
        <Grid item sm={6} xs={12}>
          <Input
            type="text"
            title="Nombre de la empresa"
            name={LeadFormFieldsEnum.COMPANY_NAME}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Escribe el nombre de la empresa"
            icon={<Groups className="primary_300" />}
            value={_.get(values, LeadFormFieldsEnum.COMPANY_NAME)}
            error={
              !!_.get(errors, LeadFormFieldsEnum.COMPANY_NAME) &&
              getFieldMeta(LeadFormFieldsEnum.COMPANY_NAME).touched
            }
            subtitle={
              getFieldMeta(LeadFormFieldsEnum.COMPANY_NAME).touched
                ? (_.get(
                    errors,
                    LeadFormFieldsEnum.COMPANY_NAME
                  ,"") as string)
                : undefined
            }
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <div className="dso_input_cont">
            <div className="dso_input_title">
              <div className="dso_input_img">
                <Groups className="custom-input-color" />
              </div>
              <div className="dso_input_text">Nivel de estudios</div>
            </div>
            <FormControl sx={{ minWidth: "100%" }} size="small">
              <OzonSelect
                itemsLabel="Nivel de estudios"
                fieldName={LeadFormFieldsEnum.EDUCATION_LEVEL}
                options={educationOptions}
                handleBlur={handleBlur}
                handleChange={handleChange}
                values={values}
                getFieldMeta={getFieldMeta}
                errors={errors}
              />
            </FormControl>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export const CivilStatusStep: React.FC<StepProps> = () => {
  useEffect(() => {
    ReactGA.event("VIEW_Financial_civilstatus", {
      category: "VIEW_Financial_civilstatus",
      label: "page view to the civil status information view",
    });
  }, []);
  const useStyles = makeStyles({
    listContainer: {
      display: "flex",
      flexDirection: "column",
      height: "auto",
    },
  });

  const classes = useStyles();

  const {
    values,
    handleChange,
    errors,
    handleBlur,
    getFieldMeta,
    setFieldValue,
  } = useFormikContext<IStepperFormValues>();

  const civilStatus = [
    { label: "Solter@", value: CivilStatusEnum.single },
    { label: "Casad@", value: CivilStatusEnum.married },
    { label: "Unión Libre", value: CivilStatusEnum.Cohabitation },
    { label: "Divorciad@", value: CivilStatusEnum.divorced },
    { label: "Viud@", value: CivilStatusEnum.widower },
  ];

  const livesWith: LivesWithEnum[] = [
    LivesWithEnum.alone,
    LivesWithEnum.spouse,
    LivesWithEnum.friends,
    LivesWithEnum.parents,
    LivesWithEnum.couple,
    LivesWithEnum.Siblings,
    LivesWithEnum.children,
  ];

  const [checkboxState, setCheckboxState] = React.useState<any>(() => {
    const state: any = {};
    livesWith.forEach((key) => {
      state[key] = (_.get(values, LeadFormFieldsEnum.LIVES_WITH, []) as LivesWithEnum[]).includes(key);
    });

    return state;
  });

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === LivesWithEnum.alone && event.target.checked) {
      setCheckboxState(() => {
        const state: any = {};
        livesWith.forEach((key: string) => {
          state[key] = false;
        });
        state[event.target.name]= event.target.checked;
        return state;
      });
    } else {
      setCheckboxState({
        ...checkboxState,
        [event.target.name]: event.target.checked,
        [LivesWithEnum.alone]: false,
      });
    }
  };

  useEffect(() => {
    const nextState = Object.keys(checkboxState).filter(
      (key) => checkboxState[key] === true
    );
    setFieldValue(LeadFormFieldsEnum.LIVES_WITH, nextState);
  }, [checkboxState]);

  return (
    <div className="animate__animated animate__fadeIn">
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item sm={6} xs={12}>
          <div style={{ marginBottom: 25 }}>
            <CustomInputTitle
              text="Estado Civil"
              icon={<CoupleLove className="custom-input-color" />}
            />
          </div>
          <FormControl sx={{ marginBottom: 3 }} size="small" fullWidth>
            <OzonSelect
              itemsLabel="Estado Civil"
              fieldName={LeadFormFieldsEnum.CIVIL_STATUS}
              options={civilStatus}
              handleBlur={handleBlur}
              handleChange={handleChange}
              values={values}
              getFieldMeta={getFieldMeta}
              errors={errors}
            />
          </FormControl>
        </Grid>
        <Grid item sm={12} xs={12}>
          <CustomInputTitle
            text="¿Con quién vives?"
            icon={<Groups className="custom-input-color" />}
          />
        </Grid>
        <Grid item sm={12} xs={12}>
          <CustomCheckBox
            options={livesWith}
            checkboxState={checkboxState}
            handleCheckboxChange={handleCheckboxChange}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export const AssetsStep: React.FC<StepProps> = () => {
  const {
    values,
    setFieldValue,
    handleBlur
  } = useFormikContext<IStepperFormValues>();

  const initialAssetsInfo = [
    { id: 0, text: AssetsEnum.NINGUNO, isActive: true, icon: NoneIcon },
    { id: 1, text: AssetsEnum.CASA, isActive: false, icon: HomeIcon },
    { id: 2, text: AssetsEnum.CARRO, isActive: false, icon: CarIcon },
    { id: 3, text: AssetsEnum.MOTO, isActive: false, icon: MotoIcon },
    { id: 4, text: AssetsEnum.OTRO, isActive: false, icon: CubeIcon },
  ];
  const [assetsInfo, setAssetsInfo] = useState(() => {
    const initValue: string[] = _.get(values, LeadFormFieldsEnum.ASSETS, []);
    if (initValue.length === 0) return initialAssetsInfo;
    return initialAssetsInfo.map((value) => {
      const otroValue = initValue.filter((v) => v !== AssetsEnum.CASA && v !== AssetsEnum.CARRO && v !== AssetsEnum.MOTO).length > 0;
      return {
        ...value,
        isActive: (value.text === AssetsEnum.OTRO && otroValue) || initValue.includes(value.text)
      };
    });
  });
  const [anotherAssets, setAnotherAssets] = useState(() => {
    const initValue = _.get(values, LeadFormFieldsEnum.ASSETS, []);
    const otroValue = initValue.filter((v) => v !== AssetsEnum.CASA && v !== AssetsEnum.CARRO && v !== AssetsEnum.MOTO);
    return otroValue.join(", ");
  });
  const [showInput, setShowInput] = useState<boolean>(
    assetsInfo.filter(({ text, isActive }) => text === AssetsEnum.OTRO && isActive).length > 0
  );

  useEffect(() => {
    const assetsList: string[] = assetsInfo
      .filter(({ isActive, text }) => isActive && text !== AssetsEnum.NINGUNO && text !== AssetsEnum.OTRO)
      .map(({ text }) => text);
    if (anotherAssets.length > 0) {
      assetsList.push(anotherAssets);
    }
    setFieldValue(LeadFormFieldsEnum.ASSETS, assetsList);
  }, [anotherAssets, assetsInfo]);

  useEffect(() => {
    if (!showInput) setAnotherAssets("");
  }, [showInput]);

  useEffect(() => {
    setShowInput(assetsInfo.filter(({ text, isActive }) => text === AssetsEnum.OTRO && isActive).length > 0);
  }, [assetsInfo]);

  const handleAssetsInfo = (text: string, isActive: boolean) => {
    const hasAssetsBesideNone = assetsInfo.filter((value) => value.isActive && value.text !== AssetsEnum.NINGUNO);

    if (hasAssetsBesideNone.length > 0 && text === AssetsEnum.NINGUNO && isActive) {
      setAssetsInfo(assetsInfo.map((value) => {
        return {
          ...value,
          isActive: value.text === AssetsEnum.NINGUNO
        };
      }));
    } else if (text !== AssetsEnum.NINGUNO && isActive) {
      setAssetsInfo(assetsInfo.map((value) => {
        return {
          ...value,
          // eslint-disable-next-line no-nested-ternary
          isActive: value.text === AssetsEnum.NINGUNO
            ? false
            : value.text === text ? isActive : value.isActive
        };
      }));
    } else {
      setAssetsInfo(assetsInfo.map((value) => {
        return {
          ...value,
          isActive: value.text === text ? isActive : value.isActive
        };
      }));
    }
  };

  const handleAnotherAssets = (e: any) => {
    setAnotherAssets(e.target.value);
  };

  useEffect(() => {
    ReactGA.event("VIEW_Financial_assets", {
      category: "VIEW_Financial_assets",
      label: "page view to the assets information view",
    });
  }, []);

  return (
    <div className="animate__animated animate__fadeIn">
      <Grid container rowSpacing={1} columnSpacing={{ xs: 2, sm: 12, md: 12 }}>
        <Grid item sm={12} xs={12}>
          <CustomInputTitle
            text="¿Con qué activo cuentas?"
            icon={<Building className="custom-input-color" />}
          />
        </Grid>
        <Grid item sm={12} xs={12}>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {assetsInfo.map((item, idx) => (
              <AssetCard
                key={idx}
                item={item}
                assetsInfo={assetsInfo}
                setAssetsInfo={(isActive: boolean) => handleAssetsInfo(item.text, isActive)}
              />
            ))}
            {showInput && (
              <Grid item sm={6} xs={6} className="m_t_md">
                <Input
                  type="text"
                  title="¿Con qué OTRO activo cuentas?"
                  name="anotherAssets"
                  onChange={handleAnotherAssets}
                  onBlur={handleBlur}
                  placeholder="Cuentanos que otro activo posees"
                  icon={<Boy className="primary_300" />}
                  value={anotherAssets}
                />
              </Grid>
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
